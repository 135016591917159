<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="固定资产清理" v-model="visible" width="90%" append-to-body>
    <div class="clear-btn">
      <qzf-button button_code="zc_qlzc" plain type="primary" size="small" @success="assetsClear">
        <el-icon>
          <Remove />
        </el-icon>&nbsp;清理资产
      </qzf-button>
    </div>
    <el-table stripe :data="list" style="width: 100%" border highlight-current-row :height="500"
      @selection-change="handleSelectionChange" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" align="center" width="55" />
      <el-table-column label="序号" type="index" align="center" fixed width="55"></el-table-column>
      <el-table-column prop="name" label="资产名称" fixed align="center" min-width="90" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="unit" label="单位" align="center" min-width="90">
      </el-table-column>
      <el-table-column prop="spec" label="规格型号" align="center" min-width="90">
      </el-table-column>
      <el-table-column prop="assetType" label="资产类型" align="center" width="150" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="subjectName" label="累计折旧科目" align="center" width="150" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="fySubjectName" label="折旧费用科目" align="center" width="150" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="count" label="数量" align="center" width="60">
      </el-table-column>
      <el-table-column prop="transDate" label="入账日期" align="center" width="120">
      </el-table-column>
      <el-table-column prop="originalValue" label="原值(元)" align="center" min-width="105">
      </el-table-column>
      <el-table-column prop="depreciationPeriods" label="预计使用月份" align="center" width="135">
      </el-table-column>
      <el-table-column prop="rmValueRate" label="残值率(小数)" align="center" min-width="110">
      </el-table-column>
      <el-table-column prop="cz" label="残值(元)" align="center" min-width="100">
        <template #default="scope">
          <span>{{ (scope.row.rmValueRate * scope.row.originalValue).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amortizeAmount" label="本月折旧(元)" align="center" min-width="105">
      </el-table-column>
      <el-table-column label="期末" align="center">
        <el-table-column prop="ljDepreciation" label="累计折旧(元)" align="center" min-width="105">
          <template #default="scope">
            <span>{{ (scope.row.ljDepreciation + scope.row.initLjDepreciation).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="netValue" label="净值(元)" align="center" min-width="105">
          <template #default="scope">
            <span>{{ scope.row.netValue < 0 ? 0 : scope.row.netValue }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="状态" align="center" min-width="180">
        <template #default="scope">
          <span v-if="scope.row.clearPeriod">{{ scope.row.clearPeriod }}账期已清理<span v-if="scope.row.clearRemark">-{{
            scope.row.clearRemark }}</span></span>
          <div v-else>
            <span v-if="scope.row.status == 2">暂停</span>
            <span v-else>正常</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
  <clearAssets ref="clearAssetsRef" @success="getList" />
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { assetList } from '@/api/assets'
import clearAssets from '../../assets/components/clearAssets.vue'
import { ElMessage } from 'element-plus'
const { proxy } = getCurrentInstance()
const visible = ref(false)
const loading = ref(false)
const list = ref([])
const total = ref(0)
const listQuery = ref({
  assetType: '',
  page: 1,
  limit: 20,
  type: 1,
  comId: proxy.$store.getters['user/comInfo'].comId * 1,
  period: proxy.$store.getters['user/comInfo'].period,
  status: 5,
  depOk: 0
})
const sels = ref([])
const clearAssetsRef = ref()
const init = () => {
  visible.value = true
  getList()
}
const getList = () => {
  loading.value = true
  assetList(listQuery.value).then(res => {
    loading.value = false
    if (res.data.msg == 'success') {
      list.value = res.data.data.list
      total.value = res.data.data.total
    }
  })
}
const handleSelectionChange = (val) => {
  sels.value = val
}
const assetsClear = () => {
  if (sels.value.length == 0) {
    ElMessage.warning('请选择资产')
    return
  }
  let fail = sels.value.find(v => v.clearPeriod)
  if (fail) {
    ElMessage.warning('存在已清理资产，请重新选择')
    return
  }
  let arr = []
  sels.value.forEach(v => {
    list.value.forEach(item => {
      if (v.id == item.id) {
        arr.push({
          id: v.id,
          name: item.name,
          clearSubjectId: "", originalSubjectId: ""
        })
      }
    })
  })
  clearAssetsRef.value.init(arr)
}

defineExpose({ init })
</script>

<style scoped lang="scss">
.clear-btn {
  margin-bottom: 10px;
  text-align: right;
}
</style>
