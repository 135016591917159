<template>
  <span style="text-align:left">
    <!-- 弹框 -->
    <!--TAB1手工入库单 -->
    <div class="zangu-container button_bg">
      <el-dialog :close-on-click-modal="false" destroy-on-close :title="name" v-model="dialogVisible" width="1350px" draggable>
        <h1 class='title'>{{name}}</h1>
        <div class="header">
          <div v-if="info.type == 3" style="display: flex;width: 250px;">
            <span>供应商:</span>
            <div style="width: 80%;">
              <corp-list :borderNone="false" v-model:corpId="info.corpId" type="2"></corp-list>
            </div>
          </div>
          <div>
            <span>日期：</span>
            <el-date-picker v-model="info.inventoryDate" :picker-options="pickerBeginDateBefore" type="date" placeholder="选择日期" size="small"></el-date-picker>
          </div>
          <div>
            <span>业务类型：
              <el-radio-group v-model="info.type" disabled>
                <el-radio :label="11">手动入库</el-radio>
                <el-radio :label="10">手动出库</el-radio>
                <el-radio :label="1">初始化入库</el-radio>
                <el-radio :label="3">暂估入库</el-radio>
              </el-radio-group>
            </span>
          </div>
        </div>
        <table cellspacing=0>
          <thead>
            <tr>
              <th class="col_operate"></th>
              <th class="bor_top">品名</th>
              <th class="bor_top">规格型号</th>
              <th class="bor_top">计量单位</th>
              <th class="bor_top">数量</th>
              <th class="bor_top">单价</th>
              <th  class="bor_top">
                <div>
                  <strong class="tit">金额</strong>
                  <div class="money_unit">
                    <span>亿</span>
                    <span>千</span>
                    <span>百</span>
                    <span>十</span>
                    <span>万</span>
                    <span>千</span>
                    <span>百</span>
                    <span>十</span>
                    <span>元</span>
                    <span>角</span>
                    <span>分</span>
                  </div>
                </div>
              </th>
              <th class="col_operate"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in info.items " :key="index" style="position: relative;">
              <td class="col_operate add" @click="handleAdd(item,index)">
                <!-- <svg-icon icon-class="add" class="svg-icon" /> -->
                <i class="iconfont icon-jiahao" style="font-size:21px;color:#17a2b8;line-height:40px"></i>
              </td>
              <td>
               <inventory-list :borderNone="true" v-model:inventoryId="item.inventoryId" v-model:spec="item.spec" v-model:unit="item.unit" :type="false"></inventory-list>
              </td>
              <td>{{item.spec}}</td>
              <td>{{item.unit}}</td>
              <td>
                <input @blur="inputCountBlur(item.count)" type="text" v-model.number="item.count" @change="handleCountInput(item)" size="small">
              </td>
              <td>
                <input @blur="inputCountBlur(item.danjia)" type="text" v-model.number="item.danjia" @change="handleDanjiaInput(item)" size="small">
              </td>
              <td class="td-money">
                <div v-if="!item.edit" @click="handleMoney(item)">
                  <span v-if="item.amount">
                    {{(Number(item.amount).toFixed(2)*100).toFixed(0)}}
                  </span>
                </div>
                <input v-else @blur="inputBlur(item)" type="text" v-model.number="item.amount" size="small">
              </td>
              <td class="col_operate close">
                <div @click="handleDel(index)">
                  <i class="iconfont icon-shanchu"></i>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <th class="col_operate"></th>
            <td colspan="5" style="padding:10px ;">
              合计：
            </td>
            <td class="td-money">
              <div>
                <span>
                  {{heji}}
                </span>
              </div>

            </td>
          </tfoot>
        </table>
        <template #footer>
          <span class="dialog-footer">
            <el-button v-if="from == 'convert'" size="small" @click="zjhs">直接核算</el-button>
            <el-button size="small" @click="dialogVisible = false">取消</el-button>
            <el-button size="small" type="primary" @click="handleSaveshougong">确认</el-button>
          </span>
        </template>

      </el-dialog>
    </div>
  </span>
<settingDialog ref="settingDialog"></settingDialog>

</template>

<script>
import { estimateSave , inventorySave} from '@/api/inventory'
import { getPeriodTime } from "@/utils/fun";
export default {
  name:'inventoryStock',
  props:{
    name:{
      type:String,
      default:'手动入库'
    },
    from:{
      type:String,
      default:'zgrk'
    }
  },
  data() {
    return {
      dialogVisible: false,
      closeStatus:false,
      info: {
        type: 0,
        inventoryDate: getPeriodTime(), //开票日期
        items: [
          {
            name: "", // 开票项目（品名？）
            spec: "", // 规格型号
            unit: "", // 单位
            count: 0, // 数量
            amount: 0 ,// 金额
            inventoryId:0
          }
        ]
      },
    }
  },
  computed:{
    heji() {
      let count = 0;
      this.info.items.map((v) => {
        if (v.amount) {
          count += Number(v.amount);
        }
      });
      return (Number(count).toFixed(2)*100).toFixed(0)
    }
  },
  methods:{
    init(items) {
      if(items){
        this.info.items = items
      }else{
        this.info.items = [
          {
            name: "", // 开票项目（品名？）
            spec: "", // 规格型号
            unit: "", // 单位
            count: 0, // 数量
            amount: 0 ,// 金额
            inventoryId:0
          }
        ]
      }
      if(this.name == "手动出库"){
        this.$confirm('手动出库仅用于调整库存，不会生成凭证。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dialogVisible = true
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消手动出库'
          });          
        });
        this.info.type = 10
        return
      }
      if(this.name == "手动入库"){
        this.$confirm('手动入库仅用于调整库存，不会生成凭证。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dialogVisible = true
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消手动入库'
          });          
        });
        this.info.type = 11
        return
      }
      this.dialogVisible = true
      if(this.name == "暂估入库"){
        this.info.type = 3
      }else if(this.name == "初始化入库"){
        this.info.type = 1
      }
    },
    handleMoney(item) {
      item.edit = true;
    },
    inputBlur(item) {
      item.edit = false;
      if (isNaN(item.amount)) {
        item.amount = 0;
        this.$message({
          type: "error",
          message: "请输入合法金额数"
        });
        return;
      }
      if(item.count != ""){
        item.danjia = (Number(item.amount) / Number(item.count)).toFixed(2);
      }
    },
    inputCountBlur(item){
      if (isNaN(item)) {
        item = 0;
        this.$message({
          type: "error",
          message: "请输入合法数"
        });
        return
      }
    },
    // 入库数量输入框
    handleCountInput(item) {
      if(item.danjia){
        item.amount = Number((Number(item.danjia) * Number(item.count)).toFixed(2))
      }
    },
    // 入库输入单价
    handleDanjiaInput(item) {
      if (isNaN(item.amount)) {
        item.amount = 0;
        this.$message({
          type: "error",
          message: "请输入合法数"
        });
        return;
      }
      if(item.count == ""){
        item.amount = Number((Number(item.danjia) * (Number(item.count) + 1)).toFixed(2));
      }else{
        item.amount = Number((Number(item.danjia) * Number(item.count)).toFixed(2));
      }
    },
    //入库输入金额
    handleMoney(item) {
      item.edit=true;
    },
    handleDel(index) {
      if (this.info.items.length == 1) {
        return;
      }
      this.info.items.splice(index, 1);
    },
    // 入库增加一行
    handleAdd(item, index) {
      this.info.items.splice(index + 1, 0, {
        name: "",
        spec: "",
        unit: "",
        count: 0,
        danjia: 0,
        amount: 0
      });
    },
    zjhs(){
      this.dialogVisible = false
      this.$emit('convert')
    },
    handleSaveshougong(){
      let counStatus = false
      this.info.items.map(v=>{
        v.amount = Number(v.amount)
        v.count = Number(v.count)
        if(v.count == 0){
          counStatus = true
        }else{
          counStatus = false
        }
      })
      // if(counStatus){
      //   this.$qzfMessage("数量不可为0",2)
      //   return
      // }
      let status = this.info.items.every(item => {
        if (!item.inventoryId) {
          return false;
        } else {
          return true;
        }
      });
      if (!status) {
        this.$message({
          type: "error",
          message: "请选择货品"
        });
        return;
      }
      if(this.info.type == 3){
        estimateSave(this.info).then(res=>{
          if(res.data.msg == "success"){
            this.dialogVisible = false
            this.$qzfMessage("新增成功")
            this.$bus.emit('voucherUpdate')
            this.$emit('success', res.data.data.info)
          }else if(res.data.msg == '未设置暂估科目'){
            let route = '/setting/habbitSettingBook'
            this.$refs.settingDialog.getInit(res.data.msg,route)
          }
        })
      }else{
        inventorySave(this.info).then(res=>{
          if(res.data.msg == "success"){
            this.dialogVisible = false
            this.$qzfMessage("新增成功")
            this.$bus.emit('voucherUpdate')
            this.$emit('success', res.data.data.info)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zangu-container {
  // text-align: center;
  .title {
    text-align: center;
    font-size: 22px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    // margin: 20px;
  }
  .bor_top {
    width: 15%;
    border-top: 1px solid #c2c2c2;
  }
  table {
    width: 100%;
    text-align: center;
    td,
    th {
      border-right: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
    }
    th:nth-child(2) ,td:nth-child(2){
      border-left: 1px solid #c2c2c2;
    }
    
    .tit {
      height: 25px;
      line-height: 25px;
      display: block;
    }
    .money_unit {
      text-align: center;
      line-height: 22px;
      height: 22px;
      font-size: 14px;
      border-top: 1px solid #dadada;
      background-image: url("http://etax.qizhangfang.net/template/eaccount/line1.png");
      background-repeat: repeat-y;
      display: flex;
      span {
        height: 100%;
        flex: 1;
        width: 18px;
        //  margin-right: 1px;
        // border-left: 1px solid #f3f3f3;
      }
    }
    .td-money {
      width: 15%;
      text-align: center;
      background-image: url("http://etax.qizhangfang.net/template/eaccount/line1.png");
      background-repeat: repeat-y;
      width: 220px;
      input {
        border: 1px solid #f15a24;
        width: 100%;
        height: 45px;
      }
      > div {
        width: 100%;
        text-align: right;
        //letter-spacing: 16px;
        letter-spacing: 10px;
        position: relative;
        right: -4px;
        height: 100%;
        line-height: 45px;
        font-size: 14px;
        span {
        height: 100%;
        flex: 1;
        width: 18px;
      }
      }
     
    }
    tbody {
      tr {
        td {
          width: 150px;
          height: 45px;
        }
      }
    }
    .col_operate {
      width: 26px;
      border: 1px solid #fff;


    }
    .add {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      font-size: 16px;
      color: #f15a24;
      cursor: pointer;
      .i {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
        margin-top: 10px;
      }
    }
    .close {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      font-size: 18px;
      color: #F56C6C;
      cursor: pointer;
    }
  }
}
</style>